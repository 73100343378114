import React, { useState } from "react";
import Expraw from "./customization/Experience.json";
import LanguageSwitcher from "./lang";

export function Exp() {
    const [selectedLang, setSelectedLang] = useState(process.env.REACT_APP_LANG || 'en'); // Default language
    const [openDetails, setOpenDetails] = useState({}); // To store the state of open details

    // Fetch experience data based on the selected language
    const experiences = Expraw.lang?.[selectedLang]?.companies || Expraw.companies;

    if (!experiences || experiences.length === 0) {
        console.error("Experience data not found for the selected language");
        return <p>No experience data available.</p>;
    }

    // Function to toggle task details visibility
    const toggleDetails = (index) => {
        setOpenDetails((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle the visibility of the details
        }));
    };

    // Render work experience
    const result = experiences.map((company, index) => (
        <div className="edu" key={index}>
            <div className="status">
                <img src={require(`${company.logo}`)} alt={`${company.name} logo`} />
            </div>
            <div className="text">
                <p className="status-company" style={{ color: "#68c9dc" }}>
                    Status: {company.status}
                </p>
                <p className="school">{company.name}</p>
                <p className="dep">{company.position_time}</p>
                {company.description.map((desc, descIndex) => (
                    <div key={descIndex} className="description-item">
                        <p
                            className="task"
                            onClick={() => toggleDetails(descIndex)} // Toggle detail onClick
                            style={{ cursor: "pointer", color: "#68c9dc" }}
                            aria-expanded={openDetails[descIndex] ? "true" : "false"} // Accessibility improvement
                        >
                            <strong>{desc.task}</strong>
                        </p>
                        {/* Display details if the task is open */}
                        {openDetails[descIndex] && (
                            <div className="details">
                                {desc.details.split("\n").map((line, lineIndex) => (
                                    <p key={lineIndex} className="details-text">
                                        {line}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    ));

    return (
        <div>
            <LanguageSwitcher
                selectedLang={selectedLang}
                onChangeLang={(lang) => setSelectedLang(lang)} // Change language
            />
            <div>{result}</div>
        </div>
    );
}

export default Exp;
