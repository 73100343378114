import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "./styles/lang.css";

function LanguageSwitcher({ selectedLang, onChangeLang }) {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(selectedLang || i18n.language);

    useEffect(() => {
        if (selectedLang && selectedLang !== currentLanguage) {
            setCurrentLanguage(selectedLang);
        }
    }, [selectedLang, currentLanguage]);

    const changeLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'id' : 'en';
        i18n.changeLanguage(newLanguage);
        setCurrentLanguage(newLanguage);
        if (onChangeLang) {
            onChangeLang(newLanguage);
        }
    };

    return (
        <div className="language-switcher">
            <button className="language-btn" onClick={changeLanguage}>
                {currentLanguage.toUpperCase()}
            </button>
        </div>
    );
}

export default LanguageSwitcher;
