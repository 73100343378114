import React from "react";
import Pubraw from "./customization/Publication.json";
import "./styles/main.css";
import "./styles/content.css";

export function Pub() {
    const journal = [];
    // eslint-disable-next-line array-callback-return
    Pubraw.journal.map((paper, index) => {
        journal.push(
            <li key={index}>
                <span>
                    <b>Title : {paper.title}</b><br />
                    Publisher : {paper.author} <br />
                    Organization : {paper.organization} <br />
                    Date Publish : {paper.date} <br />
                    <a href={paper.link} target="_blank" rel="noreferrer">[Certificate Document]</a>
                </span>
            </li>
        );
    });

    return (
        <div className="pub-list">
            <p>Certificate Owned</p>
            <ul>
                {journal}
            </ul>
        </div>
    );
}

export default Pub;
