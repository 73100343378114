import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'; // Import HOC for translations
import Menu from './menu';
import App from './App';
import './styles/main.css';
import './styles/nav.css';
import './i18n'; // Import i18n configuration
import LanguageSwitcher from './lang'; // Import LanguageSwitcher

class Container extends Component {
    constructor() {
        super();
        this.state = {
            Index: 1,
        };
        const value = localStorage.getItem('index');
        this.state.Index = value == null ? 1 : parseInt(value, 10);
    }

    goPage = (index) => {
        this.setState({ Index: index });
        localStorage.setItem('index', index);
    };

    render() {
        const { t } = this.props; // Get the translation function from props

        return (
            <div className="container">
                <Menu />
                {/* Language Switcher */}
                <LanguageSwitcher />

                <div className="nav">
                    <button
                        className={this.state.Index === 1 ? 'active' : ''}
                        onClick={() => this.goPage(1)}
                    >
                        <span>{t('Home')}</span>
                        <span className="left-command">
                            <i className="fa-solid fa-home" style={{ color: '#000000' }}></i>&nbsp;
                        </span>
                    </button>
                    <button
                        className={this.state.Index === 2 ? 'active' : ''}
                        onClick={() => this.goPage(2)}
                    >
                        <span>{t('Education')}</span>
                        <span className="left-command">
                            <i className="fa-solid fa-school" style={{ color: '#000000' }}></i>&nbsp;
                        </span>
                    </button>
                    <button
                        className={this.state.Index === 3 ? 'active' : ''}
                        onClick={() => this.goPage(3)}
                    >
                        <span>{t('Work')}</span>
                        <span className="left-command">
                            <i className="fa-solid fa-suitcase" style={{ color: '#000000' }}></i>&nbsp;
                        </span>
                    </button>
                    <button
                        className={this.state.Index === 4 ? 'active' : ''}
                        onClick={() => this.goPage(4)}
                    >
                        <span>{t('Skills')}</span>
                        <span className="left-command">
                            <i className="fa-solid fa-brain" style={{ color: '#000000' }}></i>&nbsp;
                        </span>
                    </button>
                    <button
                        className={this.state.Index === 5 ? 'active' : ''}
                        onClick={() => this.goPage(5)}
                    >
                        <span>{t('Project')}</span>
                        <span className="left-command">
                            <i
                                className="fa-solid fa-diagram-project"
                                style={{ color: '#000000' }}
                            ></i>
                            &nbsp;
                        </span>
                    </button>
                    <button
                        className={this.state.Index === 6 ? 'active' : ''}
                        onClick={() => this.goPage(6)}
                    >
                        <span>{t('Certificate')}</span>
                        <span className="left-command">
                            <i
                                className="fa-solid fa-certificate"
                                style={{ color: '#000000' }}
                            ></i>
                            &nbsp;
                        </span>
                    </button>
                </div>
                <App index={this.state.Index} />
            </div>
        );
    }
}

export default withTranslation()(Container);
