import React from "react";
import { Intro } from "./intro";
import { Edu } from "./edu";
import Pub from "./pub";
import Exp from "./exp";
import Pro from "./pro";
import Skl from "./skills";
import { ASCII } from "./tool";
import { Path, Code, Contact } from "./tool";
import "./styles/main.css";
import "./chat";
import './i18n'; // Import i18n configuration
import LanguageSwitcher from './lang'; // Import LanguageSwitcher
import { useTranslation } from 'react-i18next';

function IntroContent({ paths }) {
    const { t } = useTranslation();
    return (
        <div id='app'>
            <ASCII />
            <Path path={paths[0]} />
            <Code command={t("About Me")} />
            <Intro />
            <Path path={paths[6]} />
            <Code command={t("Contact Information")} />
            <Contact />
        </div>
    );
}

function EduContent({ paths }) {
    const { t } = useTranslation();
    return (
        <div id='app'>
            <ASCII />
            <Path path={paths[1]} />
            <Code command={t("Education")} />
            <Edu />
            <Path path={paths[6]} />
            <Code command={t("Contact Information")} />
            <Contact />
        </div>
    );
}

function ExpContent({ paths }) {
    const { t } = useTranslation();
    return (
        <div id='app'>
            <ASCII />
            <Path path={paths[2]} />
            <Code command={t("Work and Internship")} />
            <Exp />
            <Path path={paths[6]} />
            <Code command={t("Contact Information")} />
            <Contact />
        </div>
    );
}

function SklContent({ paths }) {
    const { t } = useTranslation();
    return (
        <div id='app'>
            <ASCII />
            <Path path={paths[3]} />
            <Code command={t("Skills")} />
            <Skl />
            <Path path={paths[6]} />
            <Code command={t("Contact Information")} />
            <Contact />
        </div>
    );
}

function ProContent({ paths }) {
    const { t } = useTranslation();
    return (
        <div id='app'>
            <ASCII />
            <Path path={paths[4]} />
            <Code command={t("Project")} />
            <Pro />
            <Path path={paths[6]} />
            <Code command={t("Contact Information")} />
            <Contact />
        </div>
    );
}

function PubContent({ paths }) {
    const { t } = useTranslation();
    return (
        <div id='app'>
            <ASCII />
            <Path path={paths[5]} />
            <Code command={t("Certificate")} />
            <Pub />
            <Path path={paths[6]} />
            <Code command={t("Contact Information")} />
            <Contact />
        </div>
    );
}


function App(props) {
    const { t } = useTranslation();

    // Translate paths
    const translatedPaths = [
        t('~\\Home'),
        t('~\\Education'),
        t('~\\Experience'),
        t('~\\Skills'),
        t('~\\Project'),
        t('~\\Certificate'),
        t('~\\Contact')
    ];

    return (
        <>
            <div className="app-header">
                <LanguageSwitcher /> {/* Display LanguageSwitcher */}
            </div>
            {
                props.index === 1 ? <IntroContent paths={translatedPaths} /> :
                props.index === 2 ? <EduContent paths={translatedPaths} /> :
                props.index === 3 ? <ExpContent paths={translatedPaths} /> :
                props.index === 4 ? <SklContent paths={translatedPaths} /> :
                props.index === 5 ? <ProContent paths={translatedPaths} /> :
                props.index === 6 ? <PubContent paths={translatedPaths} /> : null
            }
        </>
    );
}



export default App;
