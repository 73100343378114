import React, { useState, useEffect, useCallback, useRef } from "react";
import introData from './customization/Introduction.json';
import { db } from "./firebase"; // Adjust the path if necessary
import { setDoc, doc, collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { getUserIP, getLocationData, getWeatherData } from './getlocip'; // Import functions from getlocip.js

export function Path(props) {
    return (
        <p className="path">
            <span>
                &nbsp; <i className="fa-solid fa-computer" style={{ color: "#000" }}>&nbsp;</i>
            </span>
            <span>
                <i className="fa fa-folder-open" style={{ color: "#FFD43B" }}>&nbsp;</i>
                {props.path}
            </span>
        </p>
    );
}

export function ASCII() {
    const [dateTime, setDateTime] = useState('');
    const [weather, setWeather] = useState('');
    const [weatherIcon, setWeatherIcon] = useState('');
    const dataFetched = useRef(false);

    const showDateTime = useCallback(() => {
        const now = new Date();
        const formattedDateTime = now.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        }); // Get local time from the visitor
        setDateTime(formattedDateTime);
    }, []);

    const fetchWeather = useCallback(async () => {
        if (dataFetched.current) return;
        dataFetched.current = true;
    
        try {
            const ip = await getUserIP();
            const locationData = await getLocationData(ip);
            const { latitude: lat, longitude: lon, city, region } = locationData;
    
            const visitorId = await getOrCreateVisitorId();
    
            const localWeatherData = JSON.parse(localStorage.getItem("weatherData"));
    
            if (localWeatherData) {
                const lastUpdateTime = new Date(localWeatherData.lastupdate);
                const currentTime = new Date();
                const timeDifference = currentTime - lastUpdateTime;
    
                if (timeDifference > 3600000) {
                    if (lat && lon) {
                        const weatherData = await getWeatherData(lat, lon);
                        const formattedUpdate = new Date().toLocaleString();
                        setWeather(`Weather in ${weatherData.location} : ${weatherData.description} ${weatherIcon && <img src={weatherIcon} alt="Weather Icon" />} || Temp: ${weatherData.temperature}°C `);
                        setWeatherIcon(weatherData.iconUrl);
    
                        await saveDataToFirebase({
                            ...weatherData,
                            city,
                            region,
                            ip,
                            lastupdate: formattedUpdate
                        }, visitorId);
    
                        localStorage.setItem("weatherData", JSON.stringify({ ...weatherData, lastupdate: formattedUpdate }));
                    }
                } else {
                    setWeather(`Weather in ${localWeatherData.location} : ${localWeatherData.description} ${weatherIcon && <img src={weatherIcon} alt="Weather Icon" />} || Temp: ${localWeatherData.temperature}°C  `);
                    setWeatherIcon(localWeatherData.iconUrl);
                }
            } else {
                if (lat && lon) {
                    const weatherData = await getWeatherData(lat, lon);
                    const formattedUpdate = new Date().toLocaleString();
                    setWeather(`Weather in ${weatherData.location} : ${weatherData.description} ${weatherIcon && <img src={weatherIcon} alt="Weather Icon" />} || Temp: ${weatherData.temperature}°C `);
                    setWeatherIcon(weatherData.iconUrl);
    
                    await saveDataToFirebase({
                        ...weatherData,
                        city,
                        region,
                        ip,
                        lastupdate: formattedUpdate
                    }, visitorId);
    
                    localStorage.setItem("weatherData", JSON.stringify({ ...weatherData, lastupdate: formattedUpdate }));
                }
            }
        } catch (error) {
            console.error("Error fetching weather data:", error);
        }
    }, [weatherIcon]); // Added weatherIcon to the dependency array

    useEffect(() => {
        showDateTime();
        fetchWeather();

        const timeIntervalId = setInterval(showDateTime, 1000);

        return () => {
            clearInterval(timeIntervalId);
        };
    }, [showDateTime, fetchWeather]);

    const highlightText = introData.profile.highlight || "";

    return (
        <div className="main-container">
            <div className="ascii-container">
                <div className="ascii-content">
                    <div className="time-weather-container">
                        <p className="time-weather">
                            <span>Local Time: {dateTime} || {weather}</span> <br />
                        </p>
                    </div>
                </div>
            </div>
            <div className="highlight-container">
                <strong>{highlightText}</strong>
            </div>
        </div>
    );
}

export function Code(props) {
    return (
        <p id="code">
            &nbsp;&gt; {props.command} <br />
        </p>
    );
}

export function Contact() {
    const { profile } = introData;

    return (
        <div className="contact-footer">
            <div className="contact-content" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="contact-info">
                    <p>
                        <a href={`mailto:${profile.email}`} target="_blank" rel="noreferrer" className="contact-link">
                            <i className="fa-solid fa-envelope"></i> Email: <span>{profile.email}</span>
                        </a>
                    </p>
                    <p>
                        <a href={`https://wa.me/${profile.phone}`} target="_blank" rel="noreferrer" className="contact-link">
                            <i className="fa-brands fa-whatsapp"></i> WhatsApp: <span>{profile.phone}</span>
                        </a>
                    </p>
                </div>
                <div className="social-icons">
                    <a className="icon-link" target="_blank" href={profile.upwork} rel="noreferrer">
                        <i className="fa-brands fa-upwork"></i>
                    </a>
                    <a className="icon-link" target="_blank" href={profile.linkedin} rel="noreferrer">
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a className="icon-link" target="_blank" href={profile.x} rel="noreferrer">
                        <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a className="icon-link" target="_blank" href={profile.glints} rel="noreferrer">
                        <i className="fa-solid fa-briefcase"></i>
                    </a>
                    <a className="icon-link" target="_blank" href={profile.instagram} rel="noreferrer">
                        <i className="fa-brands fa-instagram" style={{ color: '#C13584' }}></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

const saveDataToFirebase = async (data, visitorId) => {
    try {
        await setDoc(doc(db, "visitorData", visitorId), data, { merge: true });
    } catch (error) {
        console.error("Error saving/updating data in Firebase:", error);
    }
};

const getOrCreateVisitorId = async () => {
    let visitorId = localStorage.getItem("visitorId");

    if (visitorId) {
        return visitorId;
    }

    try {
        const visitorsCollection = collection(db, "visitors");
        const q = query(visitorsCollection, orderBy("id", "desc"), limit(1));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const lastVisitor = querySnapshot.docs[0].data();
            const lastId = lastVisitor.id;
            visitorId = `visitor-${lastId + 1}`;
        } else {
            visitorId = "visitor-1";
        }

        localStorage.setItem("visitorId", visitorId);
        return visitorId;
    } catch (error) {
        console.error("Error getting or creating visitor ID:", error);
        visitorId = `visitor-${Math.random().toString(36).substr(2, 9)}`;
        localStorage.setItem("visitorId", visitorId);
        return visitorId;
    }
};