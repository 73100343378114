import React, { useState } from 'react';
import Introraw from './customization/Introduction.json'; // Import data JSON
import LanguageSwitcher from './lang'; 

const jobsselected = process.env.REACT_APP_JOBS_SELECTED; // Indeks pekerjaan yang dipilih (0, 1, atau 2)

export function Intro() {
    const [selectedLang, setSelectedLang] = useState('en'); // Default ke "en"
    const [selectedJobIndex] = useState(jobsselected); // Menyimpan indeks pekerjaan yang dipilih
    // Load data profil dari JSON yang diimpor
    const profileData = Introraw;

    // Ambil data pekerjaan berdasarkan bahasa yang dipilih
    const jobsData = profileData.lang?.[selectedLang]?.jobs || profileData.lang?.[selectedLang];
    const jobTitles = Object.keys(jobsData); // Dapatkan judul pekerjaan untuk bahasa yang dipilih

    // Periksa jika indeks pekerjaan yang dipilih valid
    if (selectedJobIndex < 0 || selectedJobIndex >= jobTitles.length) {
        console.error('Indeks pekerjaan yang dipilih tidak valid');
        return null;
    }

    // Dapatkan kunci dan deskripsi pekerjaan yang dipilih
    const selectedJobKey = jobTitles[selectedJobIndex];
    const selectedJob = jobsData[selectedJobKey];

    if (!selectedJob) {
        console.error('Deskripsi pekerjaan tidak ditemukan');
        return null;
    }

    // Fungsi untuk menyorot istilah dalam deskripsi
    const highlightTerms = (content, termsToHighlight) => {
        if (!Array.isArray(content)) return content; // Hanya proses array
        if (!Array.isArray(termsToHighlight) || termsToHighlight.length === 0) return content;

        return content.map((line, i) => {
            if (typeof line !== 'string') return line; // Lewati konten non-string

            const regex = new RegExp(`(${termsToHighlight.join('|')})`, 'gi');
            const parts = line.split(regex);

            return (
                <React.Fragment key={i}>
                    {parts.map((part, j) =>
                        termsToHighlight.some((term) => term.toLowerCase() === part.toLowerCase()) ? (
                            <strong key={j}>{part}</strong>
                        ) : (
                            part
                        )
                    )}
                </React.Fragment>
            );
        });
    };

    // Fungsi untuk merender teks dengan line break
    const renderWithLineBreaks = (text) => {
        if (typeof text !== 'string') return text; // Pastikan input adalah string
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    // Sorot konten dinamis berdasarkan pekerjaan yang dipilih
    const highlightedContent = highlightTerms(
        renderWithLineBreaks(selectedJob.description), // Gunakan deskripsi dari pekerjaan yang dipilih
        [selectedJob.highlight] // Gunakan istilah yang disorot
    );

    return (
        <div className="intro">
            {/* Language Switcher */}
            <LanguageSwitcher
                selectedLang={selectedLang} // Bahasa yang dipilih
                onChangeLang={(lang) => setSelectedLang(lang)} // Callback untuk memperbarui bahasa
            />

            {/* Top box untuk judul pekerjaan */}
            <div className="job-title"><h1>{profileData.profile.name}</h1>&nbsp;[{selectedJobKey}]</div>
            
            {/* Kontainer utama dengan foto dan konten */}
            <div className="main-container">
                <div className="left-box">
                    <img src={selectedJob.photos} alt="Profile" />
                    <a href={selectedJob.cv} target="_blank" rel="noopener noreferrer">
                        <button className="view-cv-button">
                            <i className="fas fa-passport"></i> {/* Ikon PDF */}
                            View CV
                        </button>
                    </a>
                </div>
                <div className="right-box">
                    <p>{highlightedContent}</p>

                    {/* Tombol CV dengan ikon di dalamnya */}
                    
                </div>
            </div>
        </div>
    );
}
