import React from "react";
import Eduraw from './customization/Education.json';

export function Edu({ lang = "en" }) {
    const educationData = Eduraw.lang?.[lang];

    return (
        <>
            {educationData ? (
                Object.keys(educationData).map((key) => {
                    const school = educationData[key];
                    return (
                        <div className="edu" key={school.name || Math.random()}>
                            <span className="status">
                                {school.logo ? (
                                    <img 
                                        src={require(`${school.logo}`)} 
                                        alt={`${school.name || 'No name'} logo`} 
                                    />
                                ) : null}
                            </span>
                            <span className="text">
                                <p className="school">{school.name || ''}</p>
                                {school.degree && <p className="dep">Degree: {school.degree}</p>}
                                {school.time && <p className="time">Time: {school.time}</p>}
                            </span>
                        </div>
                    );
                })
            ) : (
                <div className="edu">
                    <p>No education data available.</p>
                </div>
            )}
        </>
    );
}
