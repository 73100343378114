import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Home": "Home",
      "About Me": "About Me",
      "Education": "Education",
      "Work": "Work History",
      "Skills": "Skills",
      "Project": "Project",
      "Certificate": "Certificate List",
      "Contact Information": "Contact Information",
      "~\\Home": "~\\Home",
      "~\\Education": "~\\Education",
      "~\\Experience": "~\\Experience",
      "~\\Skills": "~\\Skills",
      "~\\Project": "~\\Project",
      "~\\Certificate": "~\\Certificate",
      "~\\Contact": "~\\Contact",
      "Work and Internship": "Work and Internship",
      "Weather in" : "Weather in",
    }
  },
  id: {
    translation: {
      "Home": "Beranda",
      "About Me": "Tentang Saya",
      "Education": "Pendidikan",
      "Work": "Riwayat Kerja",
      "Skills": "Keterampilan",
      "Project": "Proyek",
      "Certificate": "Sertifikat Saya",
      "Contact Information": "Informasi Kontak",
      "~\\Home": "~\\Beranda",
      "~\\Education": "~\\Pendidikan",
      "~\\Experience": "~\\Pengalaman",
      "~\\Skills": "~\\Keahlian",
      "~\\Project": "~\\Proyek",
      "~\\Certificate": "~\\Sertifikat",
      "~\\Contact": "~\\Kontak",
      "Work and Internship": "Riwayat Kerja dan Magang",
      "Weather in" : "Cuaca di  ",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') || process.env.REACT_APP_LANG, // Use stored language or default to English
    fallbackLng: 'en', // Fallback to English if selected language resources are unavailable
    interpolation: {
      escapeValue: false // React handles XSS protection
    },
    detection: {
      order: ['localStorage', 'navigator'], // Check localStorage first, then browser language settings
      caches: ['localStorage'] // Cache the language selection in localStorage
    }
  });

// Helper function to change language dynamically
export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  localStorage.setItem('language', lng); // Save selected language to localStorage
};

export default i18n;
