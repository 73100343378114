import { useState, useEffect, useCallback } from "react";

const weatherapi = process.env.REACT_APP_WEATHER_APIKEY;
const ipapikey = process.env.REACT_APP_IPAPI_DATA_KEY2;

export const getUserIP = async () => {
    try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        return data.ip || "Unknown IP";
    } catch (error) {
        // throw new Error("Unable to fetch IP address.");
    }
};

export const getLocationData = async (ip) => {
    if (!ip) throw new Error("IP address is undefined or invalid.");
    try {
        const response = await fetch(`https://api.ipdata.co/${ip}?api-key=${ipapikey}`);
        const data = await response.json();

        if (data && data.latitude && data.longitude) {
            return {
                ip,
                latitude: data.latitude,
                longitude: data.longitude,
                city: data.city || "Unknown City",
                region: data.region || "Unknown Region",
                country: data.country_name || "Unknown Country"
            };
        } else {
            // throw new Error("Invalid location data");
        }
    } catch (error) {
        // throw new Error("Unable to fetch location data.");
    }
};

export const getWeatherData = async (lat, lon) => {
    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${weatherapi}&units=metric&lang=id`;
    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.weather) {
            return formatWeatherData(data);
        } else {
            // throw new Error("Invalid weather data");
        }
    } catch (error) {
        // throw new Error("Unable to fetch weather data.");
    }
};

const formatWeatherData = (data) => {
    const iconCode = data.weather[0].icon;
    const iconUrl = `https://openweathermap.org/img/wn/${iconCode}.png`;
    return {
        location: `${data.name}, ${data.sys.country}`,
        description: data.weather[0].description,
        temperature: data.main.temp,
        iconUrl
    };
};


// Custom hook to fetch weather
export const useFetchWeather = () => {
    const [weather, setWeather] = useState(null);

    const fetchWeather = useCallback(async () => {
        try {
            const ip = await getUserIP();
            const locationData = await getLocationData(ip);
            const { latitude: lat, longitude: lon } = locationData;

            if (lat && lon) {
                const weatherData = await getWeatherData(lat, lon);
                const completeData = { ...locationData, ...weatherData };
                setWeather(completeData);

                // Save to Firebase
                // await saveDataToFirebase(completeData);
            } else {
                setWeather("Unable to determine location from IP address");
            }
        } catch (error) {
            console.error("Error fetching weather data:", error);
            setWeather("Unable to fetch weather data");
        }
    }, []);

    useEffect(() => {
        fetchWeather();
    }, [fetchWeather]);

    return weather;
};

export default useFetchWeather;