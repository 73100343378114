import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon, FaCloudSun } from 'react-icons/fa';
import "./styles/main-first.css";
import "./styles/main.css";
import {
    collection,
    addDoc,
    getDocs,
    query,
    where,
    updateDoc,
    doc
} from "firebase/firestore";
import { db, botToken, chatId } from './firebase';
import { getLocationData } from './getlocip';

const sendNotifications = process.env.REACT_APP_TELEGRAM_SEND_NOTIFICATIONS === 'true';

const LoadingPage = ({ onSubmit }) => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [visitorId, setVisitorId] = useState(null);
    const [visitorName, setVisitorName] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const domain = window.location.hostname;
    const currentHour = new Date().getHours();

    useEffect(() => {
        const storedVisitorId = localStorage.getItem("visitorId");
        if (storedVisitorId) {
            setVisitorId(storedVisitorId);
            fetchVisitorName(storedVisitorId);
        }
    }, []);

    const getGreeting = (hour) => {
        if (hour >= 5 && hour < 12) return { text: 'Good Morning', icon: <FaSun /> };
        if (hour >= 12 && hour < 17) return { text: 'Good Afternoon', icon: <FaCloudSun /> };
        return { text: 'Good Evening', icon: <FaMoon /> };
    };

    const fetchVisitorName = async (id) => {
        try {
            const userQuery = query(collection(db, "visitors"), where("id", "==", parseInt(id)));
            const snapshot = await getDocs(userQuery);

            if (!snapshot.empty) {
                setVisitorName(snapshot.docs[0].data().name);
            } else {
                setVisitorName("Guest");
            }
        } catch (error) {
            // console.error("Error fetching visitor name: ", error);
        }
    };

    const sendTelegramNotification = async (message) => {
        if (!sendNotifications || !botToken || !chatId) return;

        const response = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ chat_id: chatId, text: message, parse_mode: 'HTML' }),
        });

        if (!response.ok) {
            throw new Error(`Telegram API error: ${await response.text()}`);
        }
    };

    const getUserIP = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            return data.ip;
        } catch (error) {
            // console.error("Error fetching IP: ", error);
            return "Unknown";
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name.trim()) {
            setError("Please enter your name.");
            return;
        }
        setError('');
        setLoading(true);
        setIsSubmitting(true);
    
        try {
            const ip = await getUserIP();
            let locationData;
    
            try {
                locationData = await getLocationData(ip);
            } catch (error) {
                // Bypass error without throwing
                locationData = { city: "Unknown City", region: "Unknown Region", country: "Unknown Country", latitude: 0, longitude: 0 };
            }
    
            const os = window.navigator.platform;
            const browser = window.navigator.userAgent;
            const jakartaTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Jakarta" });
            const formattedTime = new Date(jakartaTime).toLocaleString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            });
    
            let currentVisitorId = localStorage.getItem("visitorId");
            if (!currentVisitorId) {
                const snapshot = await getDocs(collection(db, "visitors"));
                currentVisitorId = snapshot.size + 1;
                localStorage.setItem("visitorId", currentVisitorId);
            }
    
            setVisitorId(currentVisitorId);
    
            const message = `👤 New Visitor\n` +
                `🧑‍💻 Name: ${name}\n` +
                `🌐 IP: ${ip}\n` +
                `📍 Location: ${locationData.city || "Unknown City"}, ${locationData.region || "Unknown Region"}, ${locationData.country || "Unknown Country"}\n` +
                `📍 Coordinates: Latitude ${locationData.latitude}, Longitude ${locationData.longitude}\n` +
                `💻 OS: ${os}\n` +
                `🌐 Browser: ${browser}\n` +
                `🔗 Domain: ${domain}\n` +
                `🕒 First Access: ${formattedTime}`;
    
            await sendTelegramNotification(message);
    
            const userData = {
                id: parseInt(currentVisitorId),
                name,
                os,
                browser,
                ip,
                domain,
                location: `Latitude ${locationData.latitude}, Longitude ${locationData.longitude}`,
                FirstAccess: formattedTime,
                LastAccess: null,
            };
    
            await addDoc(collection(db, "visitors"), userData);
            onSubmit(name);
        } catch (error) {
            // console.error("Error during submission:", error);
            setError(error.message || "An error occurred while saving your data. Please try again.");
        } finally {
            setLoading(false);
            setIsSubmitting(false);
        }
    };
    
    const handleBackToProfiles = async () => {
        setLoading(true);
    
        try {
            const jakartaTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Jakarta" });
            const formattedLastAccessTime = new Date(jakartaTime).toLocaleString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            });
    
            const visitorDoc = query(collection(db, "visitors"), where("id", "==", parseInt(visitorId)));
            const snapshot = await getDocs(visitorDoc);
    
            if (!snapshot.empty) {
                const docId = snapshot.docs[0].id;
                const userRef = doc(db, "visitors", docId);
                await updateDoc(userRef, { LastAccess: formattedLastAccessTime });
    
                const ip = await getUserIP();
                const locationData = await getLocationData(ip);
    
                const message = `👤 Visitor Back to Profiles\n` +
                    `🧑‍💻 Name: ${visitorName || "Guest"}\n` +
                    `🌐 IP: ${ip}\n` +
                    `📍 Location: ${locationData.city}, ${locationData.region}, ${locationData.country}\n` +
                    `📍 Coordinates: Latitude ${locationData.latitude}, Longitude ${locationData.longitude}\n` +
                    `🔗 Domain: ${domain}\n` +
                    `🕒 Last Access: ${formattedLastAccessTime}`;
    
                await sendTelegramNotification(message);
            }
        } catch (error) {
            // console.error("Error updating LastAccess: ", error);
        } finally {
            setLoading(false);
        }
    
        onSubmit(visitorName);
    };
    
    

    const greeting = getGreeting(currentHour);

    return (
        <div className="wrapper-first">
            <div className="container-first">
                {loading ? (
                    <div className="loader-first">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <>
                        <h1 className="greeting-text">
                            {greeting.icon} {greeting.text}!
                        </h1>
                        <h2 className="welcome-text">Nice to meet you in {domain}</h2>
                        {visitorName ? (
                            <>
                                <h3 className="visitor-text">
                                    Welcome back, {visitorName}!
                                </h3>
                                <br />
                                <button
                                    className="button-first"
                                    onClick={handleBackToProfiles}
                                >
                                    Back to Profiles
                                </button>
                            </>
                        ) : (
                            <>
                                <h3 className="prompt-text">Please enter your name:</h3>
                                <form onSubmit={handleSubmit} className="form">
                                    <input
                                        type="text"
                                        placeholder="Enter your name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        className="input-first"
                                    />
                                    <button type="submit" className="button-first" disabled={isSubmitting}>
                                        Submit
                                    </button>
                                </form>
                                {error && <p className="error-text">{error}</p>}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default LoadingPage;
