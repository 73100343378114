import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import LoadingPage from './first';
import Container from './container';
import reportWebVitals from './reportWebVitals';
import Music from './music';
import './styles/animations.css';
import './styles/content.css';
import './styles/fa-all.css';
import './styles/lang.css';
import './styles/main-first.css';
import './styles/main-intro.css';
import './styles/main.css';
import './styles/music.css';
import './styles/nav.css';
import './styles/desktop-only.css'; // Tambahkan file CSS baru
import './styles/edu.css'; // Tambahkan file CSS baru

const App = () => {
  const [isFirstAccess, setIsFirstAccess] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const storedIsDesktop = localStorage.getItem('isDesktop');
    if (storedIsDesktop !== null) {
      setIsDesktop(JSON.parse(storedIsDesktop));
    } else {
      const handleResize = () => {
        setIsDesktop(window.innerWidth >= 1024); // Minimal lebar layar untuk tampilan desktop
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const handleFirstAccessSubmit = (name) => {
    setIsFirstAccess(false);
  };

  const handleRetryButtonClick = () => {
    // Memaksa agar tampilan desktop selalu diaktifkan
    setIsDesktop(true);
    localStorage.setItem('isDesktop', true); // Menyimpan status ke localStorage
    // Opsional: Anda bisa me-refresh halaman untuk memperbarui status tampilan jika diperlukan
    window.location.reload();
  };

  if (!isDesktop) {
    return (
      <div className="desktop-only">
        <div className="desktop-warning-card">
          <h1>⚠️ Tampilan Desktop Diperlukan</h1>
          <p>Website ini dirancang untuk tampilan desktop. Silakan gunakan perangkat dengan layar yang lebih besar untuk pengalaman terbaik.</p>
          <button onClick={handleRetryButtonClick} className="retry-button">Coba Lagi</button>
        </div>
      </div>
    );
  }

  return (
    <>
      {isFirstAccess ? (
        <LoadingPage onSubmit={handleFirstAccessSubmit} />
      ) : (
        <Container />
      )}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <Music />
  </React.StrictMode>
);

reportWebVitals();
